{
  "about": {
    "contact": "Contact",
    "contact-us": "Contactez-nous",
    "intro-contact": "Besoin d'assistance ? Des doutes sur les démarches à suivre ? Une piste d'amélioration à soumettre ?",
    "presentation": "Présentation",
    "services": "Services de l'application"
  },
  "account": "Compte",
  "account-validated": "Compte validé",
  "activate": "Activer",
  "add": "Ajouter",
  "address": {
    "address": "Adresse",
    "city": "Ville",
    "country": "Pays",
    "verify": "Veuillez renseigner votre adresse postale. Si vous nous l'avez déjà communiquée, merci de la vérifier afin que nous la mettions à jour.",
    "zipcode": "Code postal"
  },
  "advanced-search": "Recherche avancée",
  "alerts": {
    "aborted-cas-registration": {
      "cancel": "Annuler ma création de compte",
      "finish": "Terminer ma création de compte",
      "message": "Votre demande d'inscription avec un compte CAS n'est pas terminée. Cliquez sur \"Terminer ma création de compte\" pour la finaliser."
    },
    "close": "Fermer",
    "confirm-association-can-submit-projects": "Voulez-vous vraiment autoriser cette association à soumettre des projets ?",
    "confirm-association-cannot-submit-projects": "Voulez-vous vraiment retirer le droit de soumettre des projets à cette association ?",
    "confirm-association-publish": "Voulez-vous vraiment publier cette association dans l'annuaire ? Toutes les informations la concernant présentes sur cette fiche seront visibles publiquement.",
    "confirm-association-site": "Voulez-vous vraiment considérer cette association comme Site ? Elle n'aura temporairement plus à soumettre la charte Site.",
    "confirm-association-unpublish": "Voulez-vous vraiment dépublier cette association de l'annuaire ? Toutes les informations la concernant présentes sur cette fiche ne seront plus visibles pour le public.",
    "confirm-association-unsite": "Voulez-vous vraiment considérer cette association comme hors Site ? Elle devra soumettre la charte Site.",
    "leave-association-edition": "Êtes-vous sûr de vouloir quitter l'édition de cette fiche association ? Si vous avez effectué des modifications, celles-ci seront perdues.",
    "modify-email": "Attention, l'adresse mail est utilisée comme identifiant pour se connecter. En cas de modification, elle deviendra le nouvel identifiant du compte. Un mail de vérification sera envoyé sur cette adresse pour la valider."
  },
  "association": {
    "alert-confirm-changes": "Vous êtes sur le point de modifier les informations de cette association. Voulez-vous continuer ? Les données modifiées seront écrasées après la modification.",
    "all-selected": "Choisissez l'action à effectuer sur les associations cochées",
    "all-selected-delete": "Supprimer",
    "all-selected-disable": "Désactiver",
    "all-selected-enable": "Activer",
    "all-selected-mail": "Envoyer un email",
    "association": "Association",
    "back-directory": "Revenir à l'annuaire",
    "before-deletion-word": "association",
    "before-deletion-word-instruction": "Veuillez d'abord écrire le mot \"association\" avant de confirmer l'action : ",
    "confirm-all-changes": "Confirmer les actions",
    "confirm-all-csv-export": "Voulez-vous vraiment exporter ces associations en CSV ?",
    "confirm-all-delete": "Voulez-vous vraiment supprimer ces associations ? Attention cette action est irréversible et toutes les données seront perdues.",
    "confirm-all-disable": "Voulez-vous vraiment désactiver ces associations ?",
    "confirm-all-email": "Voulez-vous vraiment ouvrir l'interface de messagerie pour toutes ces associations (seules les associations ayant renseigné leur adresse email sont listées) ?",
    "confirm-all-enable": "Voulez-vous vraiment activer ces associations ?",
    "confirm-all-xlsx-export": "Voulez-vous vraiment exporter ces associations en format Excel ?",
    "confirm-delete": "Voulez-vous vraiment supprimer cette association ? Attention cette action est irréversible et toutes les données seront perdues.",
    "confirm-disable": "Voulez-vous vraiment désactiver cette association ?",
    "confirm-enable": "Voulez-vous vraiment activer cette association ?",
    "contact": "Contacter l'association",
    "csv-name": "Export-Associations",
    "delete": "Supprimer",
    "delete-association": "Supprimer l'association",
    "disable": "Désactiver",
    "disable-association": "Désactiver",
    "disable-project-submission": "Désactiver la soumission de projets",
    "disabled": "Désactivée",
    "email-software": "Ouvrir le logiciel d'envoi de mail (Thunderbird, Outlook, ...)",
    "email-web": "Ouvrir la page web d'envoi de mail (Partage, Gmail, ...)",
    "enable": "Activer",
    "enable-association": "Activer l'association",
    "enable-project-submission": "Activer la soumission de projets",
    "enabled": "Activée",
    "export-csv": "Exporter en CSV",
    "export-xlsx": "Exporter en format Excel",
    "go-back": "Retour",
    "labels": {
      "acronym": "Acronyme",
      "activity-field": "Domaine d'activité",
      "add-social-network": "Ajouter un réseau social",
      "address": "Adresse postale de l'association",
      "amount-members-allowed": "Nombre de membres pouvant s'inscrire dans l'association",
      "charter-date": "Date de signature de la charte des associations étudiantes",
      "current-projects": "Projets de l'année en cours",
      "institution": "Établissement",
      "institution-component": "Composante d'établissement",
      "last-goa": "Date de la dernière AGO",
      "mail": "Adresse mail de l'association",
      "name": "Nom de l'association",
      "phone": "Numéro de téléphone de l'association",
      "president-email": "Adresse email du président ou de la présidente",
      "president-name": "Nom du président ou de la présidente",
      "president-phone": "Numéro de téléphone du président ou de la présidente",
      "search": "Rechercher une association",
      "siret": "Numéro SIRET",
      "social-network-location": "Lien vers le réseau social",
      "social-network-type": "Type de réseau social",
      "social-object": "Objet de l'association",
      "socials": "Réseaux sociaux",
      "website": "Site web",
      "website-link": "Aller au site web de l'association"
    },
    "logo": {
      "pickup": "Choisir un nouveau logo",
      "remove": "Supprimer",
      "update": "Valider le changement de logo"
    },
    "more-details": "Fiche détail de l'association",
    "not-public": "Non Publiée",
    "not-site": "Non Site",
    "public": "Publiée",
    "publish-in-directory": "Publier dans l'annuaire",
    "site-in-directory": "Passer Site",
    "site": "Site",
    "titles": {
      "admin": "Administration",
      "contact": "Contact",
      "info": "Informations"
    },
    "unpublish-in-directory": "Dépublier de l'annuaire",
    "unsite-in-directory": "Passer hors Site",
    "validate-all-changes": "Valider tous les changements"
  },
  "back": "Retour",
  "back-to-dashboard": "Retourner dans mon espace",
  "back-to-home": "Retourner à l'accueil",
  "breadcrumbs": {
    "about": "À propos",
    "accessibility-declaration": "Déclaration d'Accessibilité (partiellement conforme : 80.4%)",
    "account-infos": "Modifier les informations de mon compte",
    "add-user": "Ajouter un compte",
    "archived-commission": "@:cape archivées",
    "archived-projects": "Projets archivés",
    "association-charter-detail": "Charte des associations du @:charter.site.name",
    "association-charter-validation": "Validation de la charte des associations du @:charter.site.name",
    "association-charters-detail": "Chartes par association",
    "association-dashboard": "Gérer mon association",
    "association-detail": "Fiche association",
    "charter": "Espace chartes",
    "commission": "Espace @:cape",
    "commission-dashboard": "Gestion des dossiers @:cape",
    "contact": "Contact",
    "content": "Contenu du site",
    "contents": "Contenus du site",
    "create-association": "Créer une association",
    "dashboard": "Tableau de bord",
    "directory": "Annuaire des associations",
    "edit-association": "Éditer l'association",
    "edit-association-president-delegation": "Déléguer la présidence",
    "edit-my-association": "Éditer mon association",
    "home": "Accueil",
    "legal-notice": "Mentions légales",
    "manage-associations": "Gérer les associations",
    "manage-charters": "Gérer les chartes",
    "manage-commission-dates": "Gérer les dates de commission",
    "manage-users": "Gérer les comptes",
    "multiannual-accessibility-plan": "Schéma Pluriannuel de Mise en Accessibilité",
    "password-reset": "Mot de passe oublié",
    "password-reset-confirm": "Modifier mon mot de passe",
    "privacy-policy": "Politique de confidentialité",
    "project-recap": "@:project.recap du projet",
    "project-review-recap": "@:project.recap du bilan",
    "register": "Créer un compte",
    "register-resend-email": "Reconfirmer l'adresse mail",
    "sign-charter": "Signer la charte des associations",
    "sign-charter-recap": "Récapitulatif de signature de la charte",
    "submit-project": "Demande de subventionnement",
    "submit-project-review": "@:cape - Formulaire de bilan moral et financier",
    "template-documents-library": "Bibliothèque de documents",
    "user-account": "Compte utilisateur",
    "validate-association-user-detail": "Valider l'affiliation de l'utilisateur dans l'association",
    "validate-association-users": "Valider les affiliations aux associations",
    "validate-users": "Valider les comptes"
  },
  "cancel": "Annuler",
  "cancel-delete": "Annuler la suppression",
  "cancel-search": "Annuler la recherche",
  "cape": "CAPE",
  "charter": {
    "actions": {
      "manage": "Gérer la charte",
      "reject": "Refuser la charte",
      "return": "Demander des informations complémentaires",
      "validate": "Valider la charte"
    },
    "all-charters": "Toutes les chartes",
    "association-charter-status": {
      "expired": "Charte @:charter.site.name expirée depuis le {expirationDate}",
      "no-charter": "Charte @:charter.site.name non signée",
      "processing": "Charte @:charter.site.name en cours de validation",
      "validated": "Charte @:charter.site.name valide jusqu'au {expirationDate}"
    },
    "association-charters": "Chartes de l'association",
    "comment": "Commentaire | Commentaires",
    "charter": "Charte | Chartes",
    "expiration-date": "Date d'expiration",
    "no-charter-to-show": "Aucune charte à afficher.",
    "options": {
      "download": "Télécharger le document type \"{documentName}\"",
      "manage": "Signer et gérer les chartes",
      "re-sign": "Renouveler la signature de la charte",
      "sign": "Signer la charte",
      "validate": "Valider la charte",
      "view": "Voir la charte"
    },
    "pdf-name": "Récapitulatif-Signature-Charte-",
    "processing-charters": "Chartes à valider",
    "project-fund": {
      "title": "Chartes de subventionnement"
    },
    "reject-info": "Attention, en refusant cette charte, le statut de l'association passera automatiquement à \"hors @:charter.site.name\".",
    "resign-info": "Veuillez soumettre à nouveau votre charte en prenant en compte ces éléments.",
    "returned-info": "La charte n'a pas pu être validée pour le motif suivant :",
    "sign": "Signer la charte",
    "sign-hint": "Veuillez déposer votre charte signée et valider.",
    "signed-charter": "Charte signée",
    "site": {
      "name": "site Alsace",
      "sign-form": {
        "association-infos-update": "Mise à jour de la fiche de l'association",
        "documents-upload": "Dépôt de documents",
        "recap": {
          "description": "Votre charte a été signée. Elle sera validée prochainement par un gestionnaire. Vous pouvez dès à présent suivre son avancement sur votre tableau de bord.",
          "title": "Votre charte a été signée"
        }
      },
      "title": "Charte des associations du @:charter.site.name"
    },
    "status": {
      "expired": "Charte à renouveler",
      "no-charter": "Charte à signer",
      "not-site": "Hors @:charter.site.name",
      "processing": "Charte en cours de validation",
      "rejected": "Charte refusée",
      "returned": "En attente de modification par l'association",
      "title": "Statut de la charte",
      "validated": "Charte signée et validée"
    },
    "uploaded-date": "Date de dépôt",
    "validated-date": "Date de validation",
    "view-charter": "Voir la charte | Voir les chartes"
  },
  "colon": " : ",
  "commission": {
    "add-new-date": "Ajouter une date de commission",
    "alerts": {
      "confirm-delete": "Êtes-vous sûr de vouloir supprimer cette date de commission ?",
      "confirm-update": "Êtes-vous sûr de vouloir modifier cette date de commission ?",
      "delete-warning-projects-submitted": "Impossible de supprimer cette commission car des projets ont été soumis et sont en cours de traitement."
    },
    "archived": "Commissions archivées",
    "commission": "Commission | Commissions",
    "date": "Date de commission",
    "dates": "Dates de commission",
    "export-csv": "Exporter en CSV",
    "export-name": "Export-Projets-",
    "export-pdf": "Exporter en PDF",
    "export-xlsx": "Exporter en format Excel",
    "funds": "Financeurs",
    "funds-validation-status": "Décisions des financeurs",
    "is-closed-to-projects": "Fermée à la soumission de nouveaux projets",
    "is-open-to-projects": "Ouverte à la soumission de nouveaux projets",
    "name": "Nom de la commission",
    "on-going": "Commissions en cours",
    "ongoing-or-to-come": "Commissions en cours ou à venir",
    "search-project": "Rechercher un projet par identifiant",
    "submission": "Date limite de soumission"
  },
  "consult": "Consulter",
  "content": {
    "aside": "Bloc 4",
    "body": "Bloc 2",
    "footer": "Bloc 3",
    "header": "Bloc 1"
  },
  "continue": "Continuer",
  "dashboard": {
    "account-infos": "Modifier mes informations personnelles",
    "association-list": "Liste des associations",
    "association-directory": "Annuaire des associations",
    "association-user": {
      "add-my-associations": "J'ajoute les associations dont je suis membre",
      "add-my-associations-note": "Seules les associations pouvant accueillir de nouveaux membres sur la plate-forme peuvent être sélectionnées. Veuillez contacter votre gestionnaire en cas de problème.",
      "add-my-new-associations": "J'ajoute les nouvelles associations dont je suis membre",
      "association": "Association",
      "association-documents": "Documents de l'association",
      "association-procedures": "Démarches de l'association",
      "back-to-association-dashboard": "Retourner au tableau de bord",
      "charter-status-processing": "Suivi du traitement de charte",
      "confirm-delete": "Voulez-vous vraiment supprimer le rôle de l'utilisateur pour cette association ? Une fois l'action validée le changement sera immédiat et irréversible.",
      "confirm-delete-self": "Voulez-vous vraiment quitter cette association ? Une fois l'action validée le changement sera immédiat et irréversible.",
      "delegate": "Déléguer",
      "delegate-from": "Date de début",
      "delegate-presidency": "Déléguer la présidence",
      "delegate-presidency-info": {
        "message": "Attention, vous êtes sur le point de déléguer vos droits de présidence. Merci de noter les conséquences qui en découlent. Les membres avec délégation de présidence auront alors le droit de :",
        "perm-1": "Modifier, supprimer et ajouter des informations sur la fiche annuaire de l'association et donc de l'annuaire.",
        "perm-2": "Initier, traiter, supprimer une demande de subventionnement.",
        "perm-3": "Déposer un bilan de projet."
      },
      "delegate-to": "Date de fin",
      "delete-association": "Supprimer le rôle dans l'association",
      "delete-association-self": "Quitter l'association",
      "edit-my-association": "Modifier la fiche de l'association",
      "i-delegate-presidency": "Je délègue mes droits de présidence à",
      "is-president": "Président ou présidente",
      "is-secretary": "Secrétaire",
      "is-treasurer": "Trésorier ou trésorière",
      "is-validated-by-admin": "Affiliation à l'association validée par le ou la gestionnaire",
      "is-vice-president": "Vice-président ou vice-présidente",
      "manage-association": "Gérer l'association",
      "member": "Membre",
      "my-associations": "Mes associations actuelles",
      "my-role": "Mon rôle",
      "new-associations": "Nouvelles associations",
      "no-member": "Pas d'autres membres dans l'association.",
      "not-association-member": "L'utilisateur n'est membre d'aucune association.",
      "presidency-status": "Droit de présidence",
      "project-status-processing": "Suivi du traitement des dossiers @:cape",
      "role": "Rôle",
      "you-are-not-an-association-member": "Vous n'êtes membre d'aucune association."
    },
    "cape-dashboard": "Tableau de bord @:cape",
    "charter-dashboard": "Tableau de bord chartes",
    "contents": "Contenus du site",
    "create-association": "Créer une association",
    "create-user": "Créer un nouvel utilisateur",
    "edit-or-delete-association": "Gérer les associations",
    "manage-charters": "Gérer les chartes",
    "manage-commissions": "Gérer les commissions",
    "manage-contents": "Gérer les contenus du site",
    "manage-my-account": "Mes informations",
    "manage-projects": "Gérer les demandes de subventionnement",
    "manage-template-documents": "Gérer les documents",
    "manage-users": "Gestion des utilisateurs",
    "my-associations": "Mes associations",
    "my-documents": "Mes documents",
    "my-infos": "Mes informations",
    "my-password": "Mon mot de passe",
    "my-status": "Mon statut actuel",
    "user-procedures": "Mes démarches",
    "search-user-via-cas": "Rechercher un utilisateur via CAS",
    "template-document-library": "Bibliothèque de documents",
    "template-document-library-info-message": "Pour faciliter vos démarches, retrouvez l'ensemble des documents à fournir pour vos demandes d'agréments et de subventions dans la bibliothèque de documents.",
    "update-my-status": "Pour changer de statut, merci de contacter le gestionnaire dont vous dépendez.",
    "user-management": "Gérer les informations des utilisateurs",
    "user-validation": "Valider les demandes de compte",
    "validate-changes": "Valider les changements",
    "view-projects": "Consulter les demandes de subventionnement",
    "welcome-message": "Bienvenue, {name} !"
  },
  "deactivate": "Désactiver",
  "delegate": "Déléguer",
  "delete": "Supprimer",
  "directory": {
    "associations-on-page-plural": "associations sur la page",
    "associations-on-page-singular": "association sur la page",
    "directory": "Annuaire des associations",
    "directory-advanced": "Annuaire des associations avancé",
    "found-associations-plural": "associations trouvées",
    "found-associations-singular": "association trouvée",
    "labels": {
      "association-acronym": "Sigle",
      "association-activity-field": "Domaine d'activité",
      "association-edition": "Éditer",
      "association-institution": "Établissement",
      "association-institution-component": "Composante d'établissement",
      "association-name": "Nom de l'association",
      "association-public": "Annuaire",
      "association-site": "Est @:charter.site.name",
      "association-status": "Statut"
    },
    "list": "Liste des associations",
    "no-match": "Aucune association trouvée.",
    "title": "Associations"
  },
  "discard-changes": "Abandonner les modifications",
  "documents": {
    "charters": "Chartes",
    "choose-file": "Choisir un document",
    "choose-name": "Nom du document",
    "no-document-to-show": "Aucun document à afficher.",
    "no-process-library": "Documents de la bibliothèque",
    "other-documents": "Autres documents",
    "project-documents": "Documents de projets",
    "template-documents": "Documents obligatoires pour dépôt de projet",
    "upload-new": "Déposer un nouveau document"
  },
  "download": "Télécharger",
  "edit": "Éditer",
  "error-404": {
    "description": "Le contenu que vous avez demandé est introuvable. Vous pouvez retourner sur la page d'accueil ou votre espace, si vous êtes connecté.",
    "title": "Contenu introuvable"
  },
  "footer": {
    "financial-support": "Un projet réalisé avec le soutien financier de"
  },
  "forbidden": "Non autorisé",
  "forms": {
    "accepted-formats": "Formats acceptés : ",
    "add-association": "Ajouter une association",
    "amount-members-allowed-must-be-superior-to-members": "Veuillez choisir un nombre supérieur ou égal au nombre de membres actuels.",
    "amount-student-allowed-cannot-be-inferior-to-members": "Ne peut pas être inférieur au nombre de {amount} membres inscrits sur la plateforme actuellement",
    "association-acronym": "Acronyme ou sigle de l'association",
    "association-institution": "Établissement de rattachement",
    "association-is-public": "Association visible dans l'annuaire",
    "association-is-site": "Les chartes de cette association sont à jour",
    "association-name": "Nom de l'association",
    "association-role": "Votre fonction dans l'association",
    "association-student-count": "Nombre de membres dans l'association",
    "change-content": "Modifier le contenu",
    "charter-validation-comment": "Commentaire obligatoire en cas de refus de charte ou de demande d'informations complémentaires.",
    "comment": "Votre commentaire",
    "comment-visibility": "Commentaire visible des porteurs de projets",
    "commission-date-must-be-posterior-to-submission-date": "La date de commission doit être postérieure à la date limite de soumission. Les dates ne doivent pas être passées.",
    "correct-amount-audience": "Le nombre d'étudiants doit être inférieur ou égal au nombre total de personnes visées.",
    "create-account": "Créer un compte",
    "delete-association": "Supprimer",
    "email": "Adresse mail",
    "error-university-mail-domain": "Cette adresse provient de l'@:university-name, veuillez passer par le formulaire CAS pour vous inscrire.",
    "first-name": "Prénom",
    "gdpr-consent": "L'Université de Strasbourg (Direction du Numérique et Service de la Vie Universitaire) et le Crous Strasbourg traitent les données recueillies pour vous permettre d'utiliser cette application. Pour en savoir plus sur la gestion de vos données personnelles et pour exercer vos droits, reportez-vous aux conditions générales d'utilisation et à la politique de confidentialité de la plate-forme. En cochant cette case, vous reconnaissez avoir pris connaissance du contenu de ces deux documents et en accepter les conditions sans réserve.",
    "gdpr-title": "Gestion des données personnelles",
    "group-role": "Mon statut (étudiant, membre de commission, ...)",
    "hint-delegation-to": "Laisser le champ vide pour une date indéterminée.",
    "hint-phone": "Format : 0102030405 ou +(identifiant)102030405",
    "im-association-member": "Autre membre de l'association",
    "im-association-president": "Présidente ou président de l'association",
    "im-association-secretary": "Secrétaire de l'association",
    "im-association-treasurer": "Trésorière ou trésorier de l'association",
    "im-association-vice-president": "Vice-présidente ou vice-président de l'association",
    "info-modify-association-institution": "Pour modifier l'établissement de rattachement de votre association, merci de contacter votre gestionnaire.",
    "label": "Libellé",
    "last-name": "Nom de famille",
    "legal-dates": "Veuillez saisir une date de fin postérieure à la date de début.",
    "login": "Connexion",
    "modify-email": "Modifier l'adresse mail",
    "multiple-choices-enabled": "Plusieurs choix possibles.",
    "new-email": "Nouvelle adresse mail",
    "new-password": "Nouveau mot de passe",
    "old-password": "Mot de passe actuel",
    "password": "Mot de passe",
    "password-reset-cas": "Si vous êtes étudiant, étudiante ou personnel de l'@:university-name et que vous vous connectez habituellement avec votre compte CAS, votre mot de passe ne pourra être réinitialisé de cette façon.",
    "password-reset-instructions": "Veuillez saisir l'adresse mail avec laquelle vous vous connectez d'habitude. Si cette adresse est liée à un compte, un lien réinitialisation de mot de passe vous sera envoyé.",
    "password-reset-ok": "Votre demande a bien été prise en compte, consultez votre boîte mail.",
    "passwords-are-not-equal": "Les deux mots de passe ne sont pas identiques.",
    "phone": "Numéro de téléphone",
    "project-comment-hint": "Si la demande est refusée ou retournée au porteur de projet, précisez la raison (documents manquants, projet inapproprié...)",
    "repeat-email": "Répéter l'adresse mail",
    "repeat-new-password": "Répéter le nouveau mot de passe",
    "required-address": "Veuillez saisir l'adresse postale.",
    "required-association-acronym": "Veuillez remplir l'acronyme ou le sigle de l'association.",
    "required-association-activity-field": "Veuillez sélectionner un domaine d'activité.",
    "required-association-goa-date": "Veuillez saisir une date de dernière assemblée générale.",
    "required-association-institution": "Veuillez sélectionner un établissement de rattachement",
    "required-association-name": "Veuillez remplir le nom de l'association.",
    "required-association-president-names": "Veuillez renseigner l'identité de la personne présidant l'association.",
    "required-association-socials": "Veuillez remplir le type de réseau social.",
    "required-city": "Veuillez saisir la ville.",
    "required-comment": "Veuillez saisir un commentaire.",
    "required-commission": "Veuillez sélectionner une commission.",
    "required-commission-date": "Veuillez dater la commission.",
    "required-commission-funds": "Veuillez lier des fonds à la commission.",
    "required-commission-name": "Veuillez nommer la commission.",
    "required-commission-submission-date": "Veuillez dater le jour de fin de dépôt des projets.",
    "required-country": "Veuillez saisir le pays.",
    "required-document-file": "Veuillez téléverser un document.",
    "required-document-name": "Veuillez renseigner un nom de document public.",
    "required-email": "Veuillez renseigner une adresse mail valide.",
    "required-field": "Ce champ est obligatoire : {label}",
    "required-fields": "Les champs marqués d'un astérisque (*) sont obligatoires.",
    "required-first-name": "Veuillez renseigner un prénom.",
    "required-fund": "Veuillez sélectionner un fonds.",
    "required-last-name": "Veuillez renseigner un nom de famille.",
    "required-new-email": "Veuillez saisir votre nouvelle adresse mail.",
    "required-new-password": "Veuillez saisir votre nouveau mot de passe.",
    "required-old-password": "Veuillez saisir votre ancien mot de passe.",
    "required-password": "Veuillez saisir votre mot de passe.",
    "required-phone": "Veuillez saisir un numéro de téléphone valide.",
    "required-project-activities": "Veuillez lister les activités prévues.",
    "required-project-amount-asked-previous": "Veuillez indiquer le montant demandé à ce fonds à la précédente édition.",
    "required-project-amount-audience": "Veuillez indiquer le nombre de personnes attendues au total.",
    "required-project-amount-earned-previous": "Veuillez indiquer le montant obtenu via ce fonds à la précédente édition.",
    "required-project-amount-students-audience": "Veuillez indiquer le nombre de personnes étudiantes attendues au total.",
    "required-project-association-user": "Veuillez indiquer la personne en charge de ce projet.",
    "required-project-audience": "Veuillez indiquer le public cible.",
    "required-project-budget": "Veuillez remplir le montant demandé pour ce financeur.",
    "required-project-budget-previous": "Veuillez indiquer le budget de l'édition précédente.",
    "required-project-categories": "Veuillez remplir le/les catégorie(s) du projet.",
    "required-project-commission-fund": "Veuillez sélectionner une demande de fonds liée à une commission.",
    "required-project-description": "Veuillez résumer le déroulement du projet.",
    "required-project-difficulties": "Veuillez indiquer les difficultés rencontrées.",
    "required-project-enddate": "Veuillez remplir la date de fin du projet.",
    "required-project-goals": "Veuillez indiquer les objectifs de ce projet.",
    "required-project-impact": "Veuillez décrire l'impact de votre projet sur la population étudiante.",
    "required-project-improvements": "Veuillez indiquer les améliorations possibles.",
    "required-project-income": "Veuillez indiquer les recettes totales du projet.",
    "required-project-individual-cost": "Veuillez remplir le coût par personne.",
    "required-project-individual-cost-not-null": "Le coût par personne ne peut être égal à 0.",
    "required-project-location": "Veuillez remplir le/les lieu(x) du projet.",
    "required-project-marketing": "Veuillez décrire la campagne de communication.",
    "required-project-moral-review": "Veuillez décrire le bilan moral du projet.",
    "required-project-name": "Veuillez remplir le nom du projet.",
    "required-project-outcome": "Veuillez indiquer les dépenses totales du projet.",
    "required-project-safety": "Veuillez indiquer un programme de prévention et de sécurité.",
    "required-project-startdate": "Veuillez remplir la date de début du projet.",
    "required-project-summary": "Veuillez résumer ce projet.",
    "required-project-sustainable": "Veuillez indiquer les actions menées concernant le Développement Durable et Responsabilité Sociétale.",
    "required-project-ticket": "Veuillez remplir le montant demandé par participant (billetterie).",
    "required-repeat-email": "Les deux adresses mail ne sont pas identiques.",
    "required-repeat-new-password": "Veuillez répéter votre nouveau mot de passe.",
    "required-status": "Veuillez choisir un statut. Maximum deux statuts autorisés.",
    "required-strong-password": "Veuillez saisir un mot de passe plus long et plus fort (ajoutez par exemple des majuscules, des chiffres ou des caractères spéciaux).",
    "required-valid-url": "Veuillez saisir une URL valide.",
    "required-zipcode": "Veuillez saisir le code postal.",
    "resend-email": "Renvoyer le mail de confirmation",
    "resend-email-cas": "Si vous êtes étudiant, étudiante ou personnel de l'@:university-name et que vous vous avez terminé votre inscription avec votre compte CAS, votre adresse email est déjà validée.",
    "resend-email-confirmation": "Renseignez votre adresse email pour renvoyer un mail vous permettant de la confirmer.",
    "resend-email-ok": "Votre demande a bien été prise en compte, consultez votre boîte mail.",
    "reset-password": "Mot de passe oublié ?",
    "select-association": "Sélectionner une association",
    "select-document": "Veuillez déposer un document",
    "select-establishment": "Veuillez choisir un établissement de rattachement",
    "select-project-commission-date": "Veuillez sélectionner la date de commission",
    "select-project-commission-member": "Veuillez sélectionner un/des financeur(s)",
    "send": "Envoyer",
    "social-network-location-hint": "Saisir une adresse valide ; Par exemple : https://facebook.com/",
    "social-network-type-hint": "Par exemple : Facebook",
    "social-object-hint": "Objet figurant sur les statuts de l'association",
    "strong-password-hint": "Pour la sécurité de vos données, veuillez choisir un mot de passe long et complexe (contenant des minuscules, des majuscules, des chiffres ou des caractères spéciaux).",
    "student-certificate-hint": "Obligatoire pour s'inscrire dans le bureau d'une association ou déposer des demandes de subventions.",
    "student-status-document": "Justificatif de statut étudiant",
    "student-status-document-hint": "Au moins un document obligatoire (carte étudiante ou certificat de scolarité)",
    "user-to-add": "Utilisateur à ajouter",
    "view-password": "Afficher le mot de passe"
  },
  "from": "À partir du",
  "header": {
    "about": "À propos",
    "associations": "Annuaire",
    "charter": "Charte",
    "commission": "@:cape",
    "contact": "Contact",
    "home": "Accueil",
    "login": "Connexion",
    "logout": "Déconnexion",
    "my-account": "Mon compte",
    "my-profile": "Mon espace",
    "subtitle": "La plateforme qui rythme la vie étudiante",
    "title": "Bienvenue sur"
  },
  "home": {
    "charter": {
      "button": "Espace charte",
      "charter-update": "Dernière mise à jour de la charte le",
      "title-line-1": "Charte",
      "title-line-2": "des associations du @:charter.site.name"
    },
    "commission": {
      "button": "Espace @:cape",
      "next-commission": "Prochaine commission",
      "title-line-1": "@:cape",
      "title-line-2": "Commission d'Aide aux Projets Étudiants"
    },
    "directory": {
      "button": "Consulter l'annuaire",
      "registered-associations": "associations enregistrées dans l'annuaire",
      "title-line-1": "Annuaire",
      "title-line-2": "des associations"
    }
  },
  "login": {
    "create-account": "Créer un compte",
    "im-cas-user": "Je suis étudiant, étudiante, ou je fais partie du personnel de l'@:university-name",
    "im-not-cas-user": "Je ne fais pas partie de l'@:university-name",
    "introduction-login": "Connectez-vous pour accéder à votre espace et effectuer vos démarches !\n",
    "login": "Connexion",
    "login-with-cas": "Connexion avec un compte CAS",
    "login-without-cas": "Connexion avec un compte externe"
  },
  "maintenance": {
    "title": "Maintenance",
    "page-title": "Maintenance en cours",
    "message-1": "Une opération de maintenance majeure est en cours, engendrant l'arrêt complet du service.",
    "message-2": "Veuillez nous excuser pour la gêne occasionnée."
  },
  "manage": "Gérer",
  "modify": "Modifier",
  "no": "Non",
  "notifications": {
    "negative": {
      "association-already-exists": "Ce nom est déjà pris par une autre association.",
      "association-before-deletion-word-error": "Vérification échouée, veuillez réessayer.",
      "automatic-logout": "Votre session a expiré.",
      "cas-user-already-exists": "Vous avez déjà un compte. Veuillez vous connecter.",
      "cas-user-not-validated-by-admin": "Votre compte n'est pas encore validé par les administrateurs.",
      "cas-user-redirected-login": "Veuillez créer un compte avant de vous connecter.",
      "delete-associations-error": "Les associations suivantes ne peuvent être supprimées : ",
      "different-passwords": "Les deux nouveaux mots de passe ne sont pas identiques.",
      "disable-association-error": "L'association ne peut être désactivée.",
      "disable-associations-error": "Les associations suivantes ne peuvent être désactivées : ",
      "enable-association-error": "L'association ne peut être activée.",
      "enable-associations-error": "Les associations suivantes ne peuvent être activées : ",
      "email-used": "Un compte lié à cette adresse mail existe déjà.",
      "error-400": "Requête invalide.",
      "error-401": "Veuillez vous authentifier.",
      "error-403": "Vous n'avez pas les droits nécessaires pour effectuer cette action.",
      "error-404": "La ressource demandée n'existe pas.",
      "error-405": "Impossible d'effectuer cette action.",
      "error-413": "Le fichier est trop gros.",
      "error-415": "Le fichier n'est pas au bon format.",
      "error-500": "Une erreur est survenue au niveau du serveur.",
      "error-503": "Maintenance en cours.",
      "error-mimetype": "Le format du fichier attendu est incorrect.",
      "error-title-length": "Le nom de votre fichier ne doit pas dépasser les 100 caractères.",
      "need-gdpr-consent": "Vous devez consentir à la politique de gestion des données personnelles.",
      "no-ticket": "Pas de ticket.",
      "publish-association-error": "L'association n'a pas été publiée dans l'annuaire.",
      "restricted-email": "Merci de vous connecter avec vos identifiants Établissement.",
      "site-association-error": "L'association n'a pas été passée Site.",
      "unknown-credentials": "L'identifiant et/ou le mot de passe sont erronés, veuillez réessayer.",
      "unknown-email": "Aucun compte n'a été trouvé avec cette adresse mail.",
      "unpublish-association-error": "L'association n'a pas été dépubliée de l'annuaire.",
      "unsite-association-error": "L'association n'a pas été passée hors Site."
    },
    "positive": {
      "account-created": "Compte créé.",
      "association-can-submit-projects": "L'association peut désormais soumettre des projets.",
      "association-cannot-submit-projects": "L'association ne peut plus soumettre des projets.",
      "association-logo-updated": "Le logo de l'association a été modifié avec succès.",
      "association-successfully-updated": "L'association a bien été mise à jour.",
      "associations-successfully-updated": "Les associations ont bien été mises à jour.",
      "charter-reject": "La charte a été refusée.",
      "charter-return": "La charte a été retournée à l'association pour modification.",
      "charter-signed": "La charte a été signée.",
      "charter-validate": "La charte a été validée.",
      "commission-date-deleted": "La date de commission a été supprimée.",
      "commission-date-modified": "La date de commission a été mise à jour.",
      "csv-export-associations": "Les associations ont été exportées en CSV.",
      "delegation-success": "La délégation a bien été mise à jour.",
      "delete-association": "L'association a été supprimée avec succès.",
      "delete-associations": "Les associations suivantes ont bien été supprimées : ",
      "disable-association": "L'association a été désactivée avec succès.",
      "disable-associations": "Les associations suivantes ont bien été désactivées : ",
      "document-deleted": "Le document a été supprimé.",
      "document-updated": "Le document a été mis à jour.",
      "enable-association": "L'association a été activée avec succès.",
      "enable-associations": "Les associations suivantes ont bien été activées : ",
      "get-cas-user-success": "Les données de l'utilisateur ont bien été récupérées.",
      "login-success": "Connexion réussie.",
      "logout-success": "Déconnexion réussie.",
      "new-commission-date": "La date de commission a été créée.",
      "new-document-uploaded": "Nouveau document ajouté.",
      "password-changed": "Votre mot de passe a bien été modifié.",
      "password-reseted": "Votre mot de passe a bien été réinitialisé. Vous pouvez tenter de vous connecter.",
      "project-amount-asked-updated": "Les montants des subventions alloués ont bien été enregistrés.",
      "project-commission-updated": "Les dates de commission ont été modifiées.",
      "project-dates-updated": "Les dates du projet ont été mises à jour.",
      "project-deleted": "Le projet a été supprimé.",
      "publish-association": "L'association a bien été publiée dans l'annuaire.",
      "reject-project": "Le projet a été refusé.",
      "return-project": "Le projet a été retourné au porteur.",
      "return-project-review": "Le bilan a été retourné au porteur.",
      "site-association": "L'association a bien été passée Site.",
      "unpublish-association": "L'association a bien été dépubliée de l'annuaire.",
      "unsite-association": "L'association a bien été passée hors Site.",
      "update-user-infos": "Les informations du compte ont bien été mises à jour.",
      "validate-association": "L'association a été créée avec succès.",
      "validate-content": "Le contenu a été modifié.",
      "validate-delete-user": "Le compte a bien été supprimé.",
      "validate-delete-user-association": "Le rôle de l'utilisateur dans l'association a bien été supprimé.",
      "validate-project": "Le projet a été validé.",
      "validate-project-review": "Le bilan a été validé.",
      "validate-success": "Le compte a été validé.",
      "validate-user-association": "Le rôle dans l'utilisateur dans l'association a bien été validé.",
      "xlsx-export-associations": "Les associations ont été exportées en format Excel."
    }
  },
  "optional": "facultatif",
  "page-top": "Haut de page",
  "pagination": "Pagination",
  "password": {
    "edit-password": "Modifier mon mot de passe",
    "policy": {
      "labels": {
        "low": "Mot de passe faible",
        "moderate": "Mot de passe modéré",
        "strong": "Mot de passe fort",
        "very-low": "Mot de passe très faible",
        "very-strong": "Mot de passe très fort"
      }
    }
  },
  "primary-nav": "Menu principal",
  "project": {
    "all-projects": "Tous les projets",
    "amount-asked": "Montant demandé",
    "amount-earned": "Montant obtenu",
    "amount-earned-pending": "Subvention à valider",
    "amount-earned-validated": "Subvention validée",
    "amounts-asked": "Montants demandés pour les financeurs sélectionnés",
    "applicant": "Porteur",
    "archived-projects": "Projets archivés",
    "association-user": "Responsable du projet dans l'association",
    "budget": "Budget",
    "budget-previous-edition": "Budget réel du projet (dépenses) pour l'édition précédente",
    "cannot-submit-projects": "L'association n'est pas habilitée à soumettre de nouveaux projets.",
    "categories": "Catégories",
    "change-commission": "Déplacer le projet dans une autre commission",
    "change-commission-info": "Le projet ne peut être déplacé car aucune autre commission proposant les financeurs choisis n'est ouverte pour le moment.",
    "comments": {
      "comment-metadata": "Commentaire de {user} le {date} à {hour}h{minutes}",
      "no-comment-to-show": "Aucun commentaire pour le moment.",
      "title": "Commentaires"
    },
    "commission-choice": "Choix de la commission",
    "commission-choice-hint": "Veuillez choisir la commission à laquelle vous souhaitez présenter le projet.",
    "commission-funds-choice": "Choix des financeurs",
    "commission-funds-choice-hint": "Veuillez choisir les financeurs auxquels vous souhaitez soumettre le projet. Plusieurs choix possibles.",
    "commission-funds-validation": "Financement(s) à {action} pour passage en commission",
    "confirm-project-delete": "La suppression de ce projet est définitive, tous les renseignements et documents associés seront perdus. Voulez-vous vraiment supprimer ce projet ?",
    "consult-on-dashboard": "Vous pouvez dès à présent consulter son avancement sur votre tableau de bord @:cape.",
    "contact": "Responsable",
    "contact-infos": "Informations de contact",
    "delete": "Supprimer le projet",
    "description": "Déroulement du projet",
    "description-hint": "Description des activités réalisées, modifications par rapport au programme initial.",
    "difficulties": "Difficultés rencontrées",
    "document": {
      "download-template": "Télécharger le document type",
      "use-template": "Merci de télécharger et de remplir impérativement le document type mis à disposition. Tout document non conforme entrainera le rejet du projet.",
      "verify": "Merci de vérifier que tous les documents transmis sont conformes avant de valider."
    },
    "document-budget": "Budget-Demande-Subvention-",
    "document-hint": "Cliquez sur le champ pour ajouter un document.",
    "document-hint-multiple": "Vous pouvez sélectionner plusieurs documents.",
    "documents": "Documents",
    "documents-name": "Documents-Demande-Subvention-",
    "download-budget": "Télécharger le budget prévisionnel",
    "download-files": "Télécharger tous les documents déposés",
    "download-recap": "Télécharger le @:project.recap au format PDF",
    "download-review-recap": "Télécharger le @:project.recap du bilan au format PDF",
    "edit-commission-funds-amounts": "Attribuer des subventions",
    "edit-dates": "Reporter les dates du projet",
    "form-help": "Si vous rencontrez des difficultés pour remplir ce formulaire, n'hésitez pas à prendre contact avec les responsables.",
    "general-infos": "Informations générales",
    "goals": "Objectifs visés par le projet",
    "goals-title": "Objectifs",
    "has-been-submitted": "Votre projet a été soumis",
    "id": "Identifiant",
    "impact-students": "Retombées pour les étudiants",
    "improvements": "Points à améliorer",
    "income": "Recettes",
    "individual-cost": "Coût par personne (budget total / nombre de personnes visées)",
    "individual-projects": "Projets portés à titre individuel",
    "last-modified-date": "Dernière modification",
    "manage": "Soumettre et gérer des projets",
    "marketing-campaign": "Campagne de communication",
    "modify": "Modifier le projet",
    "modify-review": "Modifier le bilan",
    "moral-review": "Bilan moral",
    "moral-review-hint": "Résultat obtenu, nombre d'étudiants impactés, partenariats développés.",
    "must-have-president-status": "Vous n'avez pas de droits de présidence dans cette association. Vous ne pouvez donc pas soumettre de nouveaux projets.",
    "my-projects": "Mes projets",
    "name": "Nom du projet",
    "new-comment": "Ajouter un commentaire",
    "no-amount-earned-comment-hint": "Commentaire obligatoire pour les subventions de 0 euros.",
    "no-project-to-show": "Aucun projet à afficher.",
    "outcome": "Dépenses",
    "partner-association": "Association co-porteuse",
    "pdf-name": "Récapitulatif-Demande-Subvention-",
    "planned-activities": "Programme des activités prévues",
    "planned-end-date": "Date de fin du projet",
    "planned-location": "Lieu(x) prévu(s) du projet",
    "planned-start-date": "Date de début du projet",
    "prevention-safety": "Programme de prévention et de sécurité",
    "previous-asked": "Subvention(s) demandée(s) pour l'édition précédente",
    "previous-earned": "Montant(s) obtenu(s) pour l'édition précédente",
    "process": "Traiter le projet",
    "processing-date": "Date de dépôt",
    "process-review": "Traiter le bilan",
    "project": "Projet | Projets",
    "projects-of": "Projets de",
    "recap": "ré'CAPE",
    "re-edition": "Réédition d'un précédent projet",
    "real-date-hint": "Modifiez la date si elle a changé depuis la soumission du projet.",
    "real-location-hint": "Modifiez le lieu s'il a changé depuis la soumission du projet.",
    "reject": "Refuser le projet",
    "rejected-projects": "Projets refusés",
    "required-documents-list": "Avant de débuter la procédure, assurez-vous de disposer des documents suivants numérisés (à noter que les documents liés à des fonds de subventionnement ne sont nécessaires que si vous postulez à ces fonds) :",
    "return": "Demander des modifications",
    "review": "Bilan",
    "review-has-been-submitted": "Votre bilan a été soumis",
    "review-return": "Demander des modifications",
    "review-validate": "Valider le bilan",
    "sign-charter": "N'oubliez pas de signer les chartes de subventionnement des financeurs liées à votre demande afin que votre dossier soit complet.",
    "status": {
      "archived": "Archivé",
      "cancelled": "Annulé",
      "draft": "Brouillon",
      "draft-processed": "En attente de modification par le demandeur",
      "processing": "Projet en attente de validation",
      "rejected": "Projet refusé",
      "review-draft": "En attente de bilan",
      "review-processing": "Bilan en attente de validation",
      "title": "Statut du projet",
      "validated": "En attente d'attribution"
    },
    "student-ticket-price": "Montant préférentiel demandé par étudiante ou étudiant (billetterie)",
    "submit": "Valider et soumettre le projet",
    "submit-new-project": "Nouveau projet",
    "submit-review": "Soumettre le bilan",
    "summary": "Résumé du projet",
    "sustainable-development": "Actions en rapport avec le DDRS (Développement Durable et Responsabilité Sociétale)",
    "target-all-amount": "Nombre de personnes visées au total",
    "target-audience": "Public visé par le projet",
    "target-students-amount": "Nombre d'étudiantes et étudiants visés",
    "ticket-price": "Montant demandé par participante ou participant (billetterie)",
    "update-dates": "Choisir de nouvelles dates pour le projet",
    "validate": "Valider le projet",
    "validated-projects": "Projets validés",
    "view": "Voir le projet",
    "view-review": "Voir le bilan"
  },
  "recap": "Récapitulatif",
  "register": {
    "back-to-home": "Retour à l'accueil",
    "email-not-verified": "Votre adresse e-mail n'a pas pu être vérifiée.",
    "email-verified": "Votre adresse e-mail a bien été vérifiée. S'il s'agit de votre première inscription, un gestionnaire va à présent étudier votre demande, vous recevrez vos codes d'accès à l'application par mail dès que votre demande sera validée.",
    "register-not-verify-email": "Adresse e-mail non vérifiée",
    "register-success": "Demande d'inscription réussie",
    "register-verify-email": "Adresse e-mail vérifiée",
    "registration-ok": "Votre demande d'inscription a bien été prise en compte. Consultez votre boîte mail pour vérifier votre adresse mail.",
    "registration-ok-cas": "Votre demande d'inscription a bien été prise en compte. Vous pouvez dès à présent vous connecter avec vos identifiants CAS."
  },
  "rejected": "Rejeté",
  "required": "obligatoire",
  "search": "Rechercher",
  "skip-to-content": "Aller au contenu",
  "status": "Statut",
  "table": {
    "first-page": "Première page",
    "last-page": "Dernière page",
    "next-page": "Page suivante",
    "previous-page": "Page précédente",
    "results-amount": "{firstResult}-{lastResult} sur {amountResults}",
    "select-all": "Sélectionner tout"
  },
  "to": "Jusqu'au",
  "university-name": "Université de Strasbourg",
  "update": "Mettre à jour",
  "user": {
    "associations": "Associations",
    "delete-association-role": "Note : Le rôle dans l'association sera supprimé après validation des changements.",
    "directory": "Annuaire des utilisateurs avancé",
    "directory-advanced": "Annuaire des utilisateurs avancé",
    "email": "E-mail",
    "first-name": "Prénom",
    "groups": "Statut",
    "has-no-association": "L'utilisateur n'est membre d'aucune association.",
    "infos": "Informations",
    "is-cas": "L'utilisateur provient de CAS",
    "is-validated-by-admin": "L'utilisateur est validé par le gestionnaire",
    "last-name": "Nom de famille",
    "phone": "Numéro de téléphone",
    "search": "Rechercher un utilisateur"
  },
  "user-groups": {
    "manager-general": "Gestionnaire général",
    "manager-institution": "Gestionnaire établissement",
    "manager-misc": "Gestionnaire Crous",
    "member-fund": "Membre de commission",
    "student-institution": "Étudiant membre d'association",
    "student-misc": "Étudiant porteur de projet individuel"
  },
  "user-manager": {
    "alert-confirm-update": "Vous êtes sur le point de modifier les informations de cet utilisateur. Voulez-vous continuer ?",
    "association-affiliation": "Affiliation",
    "association-validation": "Valider les affiliations aux associations",
    "choose-user-to-add": "Choisissez l'utilisateur que vous souhaitez ajouter.",
    "confirm-delete": "Êtes-vous sûr de vouloir supprimer ce compte ? Attention cette action est irréversible et toutes les données seront perdues.",
    "create-association": "Créer une association",
    "delete": "Supprimer",
    "delete-account-application": "Supprimer la demande de compte",
    "delete-association-user": "Supprimer l'affiliation",
    "delete-user": "Supprimer l'utilisateur",
    "management": "Gestion des comptes",
    "no-users-found": "Aucun utilisateur trouvé.",
    "register-in-new-associations": "Ajouter l'utilisateur dans de nouvelles associations",
    "user-associations": "Associations de l'utilisateur",
    "user-infos": "Informations de l'utilisateur",
    "user-status": "Statut de l'utilisateur",
    "users": "Utilisateurs",
    "users-found": "Utilisateurs trouvés",
    "validate-account": "Valider le compte",
    "validate-association-user": "Valider l'affiliation",
    "validation": "Validation des demandes de comptes"
  },
  "validate": "Valider",
  "validate-changes": "Valider les changements",
  "validated": "Validé",
  "validated-non": "Non validé",
  "validation-pending": "En attente de validation",
  "view": {
    "projects": "Voir les projets"
  },
  "with": "avec",
  "without": "sans",
  "yes": "Oui",
  "you-are-here": "Vous êtes ici"
}
